import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../assets/images/logo-light.png'
import { FiDribbble, FiFacebook, FiInstagram, FiLinkedin, FiMail, FiShoppingCart, FiTwitter } from 'react-icons/fi'

export default function Footer() {
  return (
        <footer className="py-8 bg-slate-800 dark:bg-gray-900">
            <div className="container">
                <div className="grid md:grid-cols-12 items-center">
                    <div className="md:col-span-3">
                        <Link to="#" className="logo-footer">
                            <img src={logo} className="md:ms-0 mx-auto" alt=""/>
                        </Link>
                    </div>

               

                    <div className="md:col-span-7 md:mt-0 mt-8">
                    <div className="text-end">
                            <p className="text-gray-400">© {new Date().getFullYear()} Reportek. Design & Develop with <i className="mdi mdi-heart text-red-700"></i> by <Link to="https://tkyazilim.com/" target="_blank" className="text-reset">TK Yazılım</Link>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
  )
}
