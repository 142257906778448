import React from 'react'
import { Link } from 'react-router-dom'

import about from '../assets/images/phone/half-2.png'

export default function AboutTwo() {
  return (
        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
            <div className="lg:col-span-5 md:col-span-6 md:order-2 order-1">
                <div className="pt-6 px-6 rounded-2xl bg-red-500/5 dark:bg-red-500/10 shadow shadow-red-500/20">
                    <img src={about} alt=""/>
                </div>
            </div>

            <div className="lg:col-span-7 md:col-span-6 md:order-1 order-2">
                <div className="lg:me-10">
                    <h6 className="text-red-500 uppercase text-sm font-bold tracking-wider mb-3">Elegant Design</h6>
                    <h4 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">Modern Tasarım ve <br/>Gelişmiş Özellikler </h4>
                    <p className="text-slate-400 max-w-xl">Reportek, iş yönetimini kolaylaştıran ve işletmenizin performansını artıran gelişmiş özellikler sunar. Artık önemli verilerinizi yönetmek ve analiz etmek için ihtiyacınız olan her şey tek bir uygulamada!</p>

                    <ul className="list-none text-slate-400 mt-6">
                        <li className="mb-1 flex"><i className="mdi mdi-check text-red-500 text-xl me-2"></i> Verilerinize anında erişerek hızlı ve doğru kararlar alın.</li>
        
                        <li className="mb-1 flex ms-0"><i className="mdi mdi-check text-red-500 text-xl me-2"></i> Raporlarınızı tek tıkla Excel veya PDF formatında dışa aktarın.</li>
                        <li className="mb-1 flex ms-0"><i className="mdi mdi-check text-red-500 text-xl me-2"></i> Büyük veri setlerini kolayca sıralayın ve filtreleyin.</li>
                        <li className="mb-1 flex ms-0"><i className="mdi mdi-check text-red-500 text-xl me-2"></i> Özelleştirilebilir raporlama seçenekleriyle iş yükünüzü hafifletin.</li>
                    </ul>

                </div>
            </div>
        </div>
  )
}
