import React from 'react'
import { Link } from 'react-router-dom'
import { FiArrowRight, FiMail } from 'react-icons/fi'

import Navbar from '../components/navbar'
import Partner from '../components/partner'
import FeaturesTwo from '../components/features-two'
import AboutOne from '../components/about-one'
import AboutTwo from '../components/about-two'
import Screenshot from '../components/screenshot'
import Faq from '../components/faq'
import Download from '../components/download'
import Client from '../components/client'
import Contact from '../components/contact'
import Footer from '../components/footer'

import hero from '../assets/images/phone/home-phone.png'

export default function IndexFour() {
  const handleSubscribe = (e: React.FormEvent) => {
    e.preventDefault();
    const contactSection = document.getElementById('contact');
    if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToContact = (e: React.MouseEvent) => {
    e.preventDefault();
    const contactSection = document.getElementById('contact');
    if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
        <Navbar navLight={false} playBtn={true} bgLight={true} navCenter={false}/>

        <section className="relative table w-full py-24 overflow-hidden" id="home">
            <div className="container relative">
                <div className="relative grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
                    <div className="md:col-span-6">
                        <div className="md:me-6">
                            <h6 className="text-sm font-bold tracking-wider mb-3">#Tüm ERP Sistemleriyle Uyumlu Tek Uygulama</h6>
                            <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-[54px] mb-5">Artık Önemli <br/>Kararları Ertelemeyin </h4>
                            <p className="text-slate-400 text-lg max-w-xl mx-auto">"Reportek ile tüm işletme verileriniz avucunuzun içinde. İster ofiste olun ister yolda; işletmenizin nabzını her daim elinizde tutun."</p>

                          
        
                            <span className="text-slate-400 font-medium">Daha fazla bilgi için <Link to="" onClick={scrollToContact} className="text-red-500 hover:text-red-600 transition-all">bizimle iletişime geçin</Link></span>
                        </div>
                    </div>

                    <div className="md:col-span-6">
                        <div className="relative after:content-[''] after:absolute after:md:bottom-48 after:-bottom-20 lg:after:-start-10 md:after:-start-20 after:-start-24
                        after:bg-red-500 after:shadow-2xl after:shadow-red-500/40 after:z-1 ltr:after:rotate-[130deg] rtl:after:-rotate-[130deg] after:w-[75rem] after:md:h-[45rem] after:h-[30rem] after:rounded-[30rem]">
                            <img src={hero} className="lg:max-w-[600px] md:max-w-md relative z-2 mover" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-6 border-t border-b border-gray-100 dark:border-gray-800">
            <div className="container relative">
                <Partner/>
            </div>
        </section>

        <section className="relative md:py-24 py-16" id="features">
            <div className="container relative">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h6 className="text-red-500 uppercase text-sm font-bold tracking-wider mb-3">Features</h6>
                    <h4 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">Reportek ile İş Yönetimini Mobilize Edin</h4>

                    <p className="text-slate-400 max-w-xl mx-auto">Reportek ile tüm işletme verileriniz avucunuzun içinde.</p>
                </div>

                <FeaturesTwo/>
            </div>

            <div className="container relative md:mt-24 mt-16">
                <AboutOne/>
            </div>
            <div className="container relative md:mt-24 mt-16">
                <AboutTwo/>
            </div>
        </section>

        <section className="relative md:py-24 py-16 bg-slate-50/50 dark:bg-slate-800/20" id="screenshot">
            <div className="container relative">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h6 className="text-red-500 uppercase text-sm font-bold tracking-wider mb-3">Screenshots</h6>
                    <h4 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">Uygulama Görselleri ile Tanışın</h4>

                    <p className="text-slate-400 max-w-xl mx-auto">Reportek'in Modern ve Kullanıcı Dostu Arayüzü</p>
                </div>

                <Screenshot/>
            </div>
        </section>

        <section className="relative overflow-hidden md:py-24 py-16" id="faqs">
            <Faq/>
        </section>

        <section className="relative md:py-24 py-16 bg-slate-50/50 dark:bg-slate-800/20" id="download">
            <Download/>
        </section>

    

        <section className="relative md:py-24 py-16 bg-slate-50/50 dark:bg-slate-800/20" id="contact">
            <Contact/>
        </section>

        <Footer/>
       
    </>
  )
}
