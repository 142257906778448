import React, { useState } from 'react'
import contact from '../assets/images/contact.svg'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        comments: ''
    });

    const [loading, setLoading] = useState(false);

    const validateForm = () => {
        if (!formData.name || !formData.email || !formData.subject || !formData.comments) {
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(formData.email);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        if (!validateForm()) {
            toast.error('Lütfen tüm alanları doldurun ve geçerli bir email adresi girin.');
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post('https://reportek.app/mail.php', formData);
            
            if (response.data.success) {
                toast.success('Mesajınız başarıyla gönderildi!');
                setFormData({ name: '', email: '', subject: '', comments: '' });
            } else {
                toast.error(response.data.message || 'Bir hata oluştu. Lütfen tekrar deneyin.');
            }
        } catch (error) {
            toast.error('Bir hata oluştu. Lütfen tekrar deneyin.');
            console.error('Mail gönderme hatası:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <div id="contact" className="container relative">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                <div className="lg:col-span-7 md:col-span-6">
                    <img src={contact} alt=""/>
                </div>

                <div className="lg:col-span-5 md:col-span-6">
                    <div className="lg:ms-5">
                        <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                            <h6 className="text-red-500 uppercase text-sm font-bold tracking-wider mb-3">İletişim</h6>
                            <h4 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">Bizimle İletişime Geçin !</h4>

                            <form onSubmit={handleSubmit}>
                                <div className="grid lg:grid-cols-12 gap-3">
                                    <div className="lg:col-span-6">
                                        <div className="text-start">
                                            <label htmlFor="name" className="font-medium">İsminiz:</label>
                                            <input 
                                                name="name" 
                                                id="name" 
                                                type="text" 
                                                value={formData.name}
                                                onChange={handleChange}
                                                className="form-input mt-1 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-red-500 dark:border-gray-800 dark:focus:border-red-500 focus:ring-0" 
                                                placeholder="İsminiz"/>
                                        </div>
                                    </div>

                                    <div className="lg:col-span-6">
                                        <div className="text-start">
                                            <label htmlFor="email" className="font-medium">Email Adresiniz:</label>
                                            <input 
                                                name="email" 
                                                id="email" 
                                                type="email" 
                                                value={formData.email}
                                                onChange={handleChange}
                                                className="form-input mt-1 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-red-500 dark:border-gray-800 dark:focus:border-red-500 focus:ring-0" 
                                                placeholder="Email"/>
                                        </div>
                                    </div>
                                    <div className="lg:col-span-12">
                                        <div className="text-start">
                                            <label htmlFor="subject" className="font-medium">Konu:</label>
                                            <input 
                                                name="subject" 
                                                id="subject" 
                                                value={formData.subject}
                                                onChange={handleChange}
                                                className="form-input mt-1 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-red-500 dark:border-gray-800 dark:focus:border-red-500 focus:ring-0" 
                                                placeholder="Konu"/>
                                        </div>
                                    </div>

                                    <div className="lg:col-span-12">
                                        <div className="text-start">
                                            <label htmlFor="comments" className="font-medium">Mesajınız:</label>
                                            <textarea 
                                                name="comments" 
                                                id="comments" 
                                                value={formData.comments}
                                                onChange={handleChange}
                                                className="form-input mt-1 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-red-500 dark:border-gray-800 dark:focus:border-red-500 focus:ring-0" 
                                                placeholder="Mesajınız"></textarea>
                                        </div>
                                    </div>

                                    <div className="lg:col-span-12">
                                        <button 
                                            type="submit" 
                                            disabled={loading}
                                            className={`py-2 px-5 tracking-wider inline-flex items-center justify-center font-medium rounded bg-red-500 text-white ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
                                            {loading ? 'Gönderiliyor...' : 'Mesaj Gönder'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
